<template lang="pug">
div
  v-btn(@click="send" :loading="loadingImport" dark :color="color" x-small).cbr-account-btn {{ status }}
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'
export default {
  mixins: [errorsMixin],

  props: {
    ID: {
      type: Number,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    color: String,
    loading: Boolean
  },

  data:() => ({
    loadingStarted: false
  }),

  computed: {
    loadingImport() {
      if(this.loading) return true
      if(this.loadingStarted) return true
      return false
    }
  },

  inject: ['svc'],

  methods: {
    submit() {
      this.send()
    },

    async send() {
      try {
        this.loadingStarted = true
        let ids = [this.ID]
        await this.svc.importAccounts(ids)
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    }
  },

  watch: {
    status(newVal, oldV) {
      this.loadingStarted = false
    }
  }
}
</script>

<style lang="scss" scoped>
.cbr-account-btn {

}
</style>